import { buildTextResourcesMH, Domains } from "../../core/textResourceScopes";

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: Domains.car,
  resources: {
    detailsPageTitle: "Details",
    listPageTitle: "Cars",

    // moved to TileFields Enum
    // titleDataBlock: "Technical Data",
    // titleDealerBlock: "Dealer Info",
    // titleConditionBlock: "Condition",
    // titleEquipmentBlock: "Equipment",
    // titleQuickCarCheckBlock: "Quick Car Check",
    // titleAssessmentBlock: "Professional Car Check",
    // titleDatEquipmentBlock: "DAT Equipment",

    addImages: "Add Images",
    subTitleAddImages: "Add at least 5 images",
    // addImages: "Bilder upload",
    // subTitleAddImages: "Laden Sie mindestens 5 Bilder hoch",

    subTitleAssessmentBlock: "Nicht erforderlich, aber empfohlen.",

    nextStepsBlock: "Next Steps",
    actionsBlock: "Actions",

    //  titleInfoBlock: "{{maker}} {{model}} {{variant}}",
    vinOverline: "{{vin}}",
    carInfoTitle: "{{maker}} {{model}} {{variant}}",
    carInfoSubTitle: "{{info}}",
    subTitleInfoBlock: "{{vin}}",
    baseData: "Base Data",
    bottomData: "Bottom Info Data",

    imageCropDlgTitle: "Edit Image",
    docEditDlgTitle: "Edit Document",
    docEditDlgTxt: "Document Name",
    docEditDlgBtnShow: "Show Document",
    legacyImagesNotSupportedInfo: "Edit of legacy images is not supported.",

    actionAuction: "Auction",
    actionAuctionSecondaryText: "Start an auction",
    actionDeleteCar: "Delete",
    actionDeleteCarSecondaryText: "Delete this car",
    documentsTitle: "Documents",
    addImagesAndDocuments: "Add more Images & Documents"
  }
});
