/**
 * UserOverview
 */

import { AccountState, buildSelectOptions, buildTextResources4Enum } from "@emibee/lib-app-common";
import { DateRangeFetchMoreArgs, MultiSelectFetchMoreArgs } from "@emibee/lib-shared";
import { AuctionAccountListTypes, IUserMin, SearchAccountsArgs } from "@mh/common";
import { Domains, enumNamespace } from "../../core/textResourceScopes";

import { UserMinDataKit } from "./UserMin";

export interface UserOverview extends IUserMin {
  deactivated: boolean;
  deletionRequested?: boolean;
  deletionRequestedAt?: number;
  status: ResellerStatus;
  // isSystemAdmin: boolean;
  confirmed: boolean;
  lastLogin?: Date;
  createdAt: number;
  affiliateCode: string;
  stateCode?: string;
  zip?: string;
  countryCode?: string;
  isPremiumUser?: boolean;
}
interface GetAuctionAccountListArgs {
  auctionId: string;
  listType: AuctionAccountListTypes;
}

const userOverviewTextResources = UserMinDataKit.extendTextResources<UserOverview>({
  deactivated: "deactivated",
  deletionRequested: "deletion requested",
  deletionRequestedAt: "deletion requested at",
  status: "Reseller Status",

  lastLogin: "Last Login",
  createdAt: "Member since",
  affiliateCode: "Affiliate Code",

  stateCode: "State",
  zip: "Zip",
  countryCode: "Country",
  isPremiumUser: "Premium User"
});

export enum ResellerStatus {
  rejected,
  pending,
  confirmed
}

const resellerStatusTextResources = buildTextResources4Enum<typeof ResellerStatus>({
  scope: "Data",
  namespace: enumNamespace(Domains.account, "ResellerStatus"),
  resources: {
    rejected: "rejected",
    pending: "pending",
    confirmed: "confirmed"
  }
});

export interface GetAccountListArgs {
  deactivated?: boolean;
  dealerId?: string;
  accountIds?: string[];
}
export const UserOverviewDataKit = UserMinDataKit.extend(userOverviewTextResources)
  .fieldType("dateTime", "createdAt", "lastLogin")
  .field("status")
  .props({
    options: buildSelectOptions(ResellerStatus, resellerStatusTextResources)
  })
  .queries(qb => ({
    getAccountList: qb
      .query("getAccountList", { includeCV: true })
      .args<MultiSelectFetchMoreArgs<GetAccountListArgs, AccountState>>("GetAccountListInput", true)
      .incrementalFetch("MultiSelectN")
      .ListResult.allFields(false)
      .build(),
    // getAccountListMax: qb
    //   .query("getAccountList")
    //   .args<MultiSelectFetchMoreArgs<GetAccountListArgs, AccountState>>("GetAccountListInput", true)
    //   .incrementalFetch("MultiSelect")
    //   .ListResult.allFields(true)
    //   .subFields("ownerOrg", "name", "orgType", "id")
    //   .build(),
    getLatestAccountsList: qb
      .query("getLatestAccountsList", { includeCV: true })
      .args<DateRangeFetchMoreArgs<GetAccountListArgs>>("GetAccountListInput", true)
      .incrementalFetch("DateRange")
      .ListResult.allFields(false)
      .build(),
    search: qb
      .query("searchAccounts")
      .args<SearchAccountsArgs>("SearchAccountsInput", true)
      .ListResult.allFields(true)
      .build(),
    getAuctionAccountList: qb
      .query("getAuctionAccountList")
      // .args<MultiSelectFetchMoreArgs<GetAuctionAccountListArgs, AccountState>>("GetAuctionAccountListArgs", true)
      .args<GetAuctionAccountListArgs>("GetAuctionAccountListArgs", true)
      //  .incrementalFetch("MultiSelectN")
      .incrementalFetch("DateRange")
      // .ListResult.allFields()
      .ListResult.selectFields(
        "id",
        "vDisplayName",
        "firstname",
        "lastname",
        "username",
        "email",
        "phone",
        "zip",
        "countryCode"
      )
      .build(), // Subscriptions
    accountChanged: qb
      .subscription("accountChanged", { typeName: "Account" })
      .args()
      .Result.allFields(true)
      .build()
  }))
  .build();
