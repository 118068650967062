"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionListTypes = exports.ReminderTypes = exports.AuctionType = exports.ProspectState = exports.AuctionState = exports.DUTCH_AUCTION_CONFIG = void 0;
exports.DUTCH_AUCTION_CONFIG = {
    STEP_DURATION: 15, // seconds
    MAX_DURATION: 360, // minutes
    MIN_DURATION: 15, // minutes
};
var AuctionState;
(function (AuctionState) {
    AuctionState[AuctionState["created"] = 0] = "created";
    AuctionState[AuctionState["started"] = 1] = "started";
    AuctionState[AuctionState["ended"] = 2] = "ended";
    AuctionState[AuctionState["canceled"] = 3] = "canceled";
})(AuctionState || (exports.AuctionState = AuctionState = {}));
var ProspectState;
(function (ProspectState) {
    ProspectState[ProspectState["open"] = 0] = "open";
    ProspectState[ProspectState["sold"] = 1] = "sold";
    ProspectState[ProspectState["notSold"] = 2] = "notSold";
})(ProspectState || (exports.ProspectState = ProspectState = {}));
var AuctionType;
(function (AuctionType) {
    AuctionType[AuctionType["single"] = 1] = "single";
    AuctionType[AuctionType["bundle"] = 2] = "bundle";
    AuctionType[AuctionType["prospect"] = 3] = "prospect";
    AuctionType[AuctionType["dutch"] = 4] = "dutch";
    AuctionType[AuctionType["spotbuy"] = 5] = "spotbuy";
})(AuctionType || (exports.AuctionType = AuctionType = {}));
var ReminderTypes;
(function (ReminderTypes) {
    ReminderTypes[ReminderTypes["PICKUP"] = 0] = "PICKUP";
    ReminderTypes[ReminderTypes["CONTRACT"] = 1] = "CONTRACT";
    ReminderTypes[ReminderTypes["PAYMENT"] = 2] = "PAYMENT";
    ReminderTypes[ReminderTypes["DEALER_DOCUMENTS"] = 3] = "DEALER_DOCUMENTS";
    ReminderTypes[ReminderTypes["AUCTION_STATUS"] = 4] = "AUCTION_STATUS";
})(ReminderTypes || (exports.ReminderTypes = ReminderTypes = {}));
// export interface IAuctionDetails<IDType = string> extends IAuction<IDType> {
//   // _visitors: [IUser];
//   // _starredBy: [IUser];
//   // startedBy: IUserMin;
// }
// export interface IAuctionCommon {
//   _id: string;
//   // highestBid: { type: Number; default: 0 };
//   highestBid: number;
//   auctionType: AuctionType;
//   // startedBy: { type: Schema.Types.ObjectId; ref: "User" }; // Referenz auf User der die Auktion gestartet hat
//   // createdBy: { type: Schema.Types.ObjectId; ref: "User" }; // Referenz auf User der die Auktion gestartet hat
//   startedBy: IUserMin; // Referenz auf User der die Auktion gestartet hat
//   createdBy: IUserMin; // Referenz auf User der die Auktion gestartet hat
//   createdAt: {
//     // Zeitpunkt an dem die Auktion gestartet wurde.
//     type: Date;
//   };
// }
// export interface IAuctionVirtuals {
//   id: string;
//   vin: string;
//   maker: string;
//   model: string;
// }
// export interface IAuctionBasisV2 {
//   bids: [
//     {
//       bidderId: string;
//       amount: number;
//       createdAt: Date;
//     }
//   ];
//   highestBidderId: string;
//   ownerOrgId: string;
//   auctionState: AuctionState;
//   car: string;
//   additionalCars: [string];
//   // car: { type: Schema.Types.ObjectId; ref: "Car" };
//   // additionalCars: [{ type: Schema.Types.ObjectId; ref: "Car" }];
//   visitors: [{ userId: string; isStarred: boolean }];
// }
// export interface IAuctionBasis {
// }
// export interface LegacyAuctionData extends OrgOwnedObjectContract<string> {
//   auctionEnd?: Date | null;
//   prospectDate?: Date | null;
//   dueDate?: number;
//   carId?: string;
//   carBundleIds?: string[];
//   targetPrice?: number;
//   reservePrice?: number;
//   priceRange?: number[];
//   stepAmount?: number;
//   durationInMillis?: number;
//   startPrice?: number;
//   spotBuyPrice?: number;
//   auctionType: AuctionType;
//   startedBy?: IUser;
//   ownerOrgId: string;
//   // ... other fields as required
// }
/*
 * ---------------------- ARGS ----------------------
 */
var AuctionListTypes;
(function (AuctionListTypes) {
    AuctionListTypes[AuctionListTypes["dealer"] = 0] = "dealer";
    AuctionListTypes[AuctionListTypes["account"] = 1] = "account";
})(AuctionListTypes || (exports.AuctionListTypes = AuctionListTypes = {}));
