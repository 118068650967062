import { buildTextResourcesMH, ViewNames } from "../../core/textResourceScopes";

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: ViewNames.personalInfo,
  resources: {
    headerPersonal: "Personal Info & Settings",
    headerOrg: "Organization Info & Settings",
    tabAccount: "Account",
    tabInfo: "Info",
    tabAccounts: "Accounts",
    tabAddresses: "Addresses",
    tabFiles: "Files",
    tabContacts: "Contacts",
    tabSettings: "Settings",
    tabBlocklist: "Blocklist",
    orgSectionHeaderMainAddress: "Main Address",
    lang: "Language",
    authorModus: "Text Resource Authoring",
    actionChangePassword: "Change",
    actionChangePasswordTitle: "Change Password",
    actionChangePasswordDescription:
      "Here You can change Your current password. You will need Your current password to do so. Otherwise You have to recover Your password.",
    mainAddress: "Main Address",
    billingAddress: "Billing Address",
    noAdditionalContactsFound: "No Contacts found.",
    titleChangePass: "Change Password",
    password: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    btnSubmit: "Change Password",
    btnCancel: "Cancel",
    validationPasswordMismatch: "Password does not match",
    changeOrgName: "Change Organization Name",

    actionDeleteBlacklistEntrySuccessToast: "User successfully removed from Blacklist.",
    actionDeleteBlacklistEntryQueryTitle: "Removed user from Blacklist",
    actionDeleteBlacklistEntryQueryText: "Do you really want to removed user from Blacklist.",
    actionBlockUsersButton: "Block User",
    deleteEmailButton: "Delete eMail",
    blockUserDialogTitle: "Block User",
    addEmailButton: "Add eMail"
  }
});
