import { buildTextResources4Data, dataKitBuilder } from "@emibee/lib-app-common";
import {
  AuctionDetailsArgs,
  AuctionMailsSent,
  AuctionReminder,
  AuctionStatistics,
  BidderInfo,
  DeleteAuctionArgs,
  EditAnnotationsArgs,
  GetBidHistoryArgs,
  IAuctionAnnotation,
  IAuctionFees,
  IAuctionGraph,
  IBid,
  ICarV2,
  IUserMin,
  LastBid,
  LegacyAuctionRenegotiation,
  LegacyAuctionRenegotiationArgs,
  LegacyCloneCarArgs,
  LegacyRetriggerAuctionMailArgs,
  LegacyStartAuctionArgs,
  PubSubEventTypeMH,
  SendAuctionReminderArgs,
  SetDealStatusArgs,
  AcceptAlternativeBidArgs
} from "@mh/common";
import { CarDetailsDataKit } from "../car";

import { dataNamespace, Domains } from "../../core/textResourceScopes";
// import { DealerDataKit } from "../dealer";
import { UserMinDataKit } from "../user";
// import { auctionTypeTextResources } from "./AuctionMin";
import { CommonSubscribeByOrgIdArgs } from "@emibee/lib-shared";

/**
 * AuctionDetails
 */
// export type IAuctionDetailsUI = IAuctionListItemUI &
export type IAuctionDetailsUI = Pick<
  IAuctionGraph,
  | "id"
  | "auctionType"
  | "auctionEndTime"
  | "targetPrice"
  | "reservePrice"
  | "isProspectDeal"
  | "startPrice"
  | "stopPrice"
  | "stepAmount"
  | "ownerOrgName"
  | "ownerOrgId"

  // | "vin"
  // | "maker"
  // | "model"
  // | "auctionType"
  // | "dealerId"
  // | "dealerName"
  // | "hasAuctionEnded"
  // | "startBid"
  // | "highestBid"
  // | "_highestBidder"
  // | "lastBid"
  | "statistics"
  // | "auctionEndTime"
  // | "mediaFiles"
  | "mainImage"
  // | "_dealer"
  // | "_visitors"
  // | "_starredBy"
  // | "_car"
  // | "_carBundle"
  | "car"
  | "carBundle"
  | "auctionReminders"
  | "auctionMailsSent"

  // | "startedBy"
  // | "auctionStartTime"
  // | "reservePrice"
  // | "isProspectDeal"
  // | "targetPrice"
  // | "steps"
  | "createdAt"
  | "auctionFees"
  | "annotations"
  | "prospectDate"
  | "dueDate"
  | "state"
  | "bidderInfo"
  | "auctionDuration"
  | "alternativePriceAccepted"
> & {
  // visitors?: IUserMin[];
  // starredBy: IUserMin[];
  // car: ICarV2;
  // carBundle: ICarV2[];
  lastBid: LastBidUI;
  bidderInfo?: BidderInfoUI;
  startedBy: IUserMin;
  renegotiation: LegacyAuctionRenegotiation;
};
// export enum ReminderTypes {
//   PICKUP,
//   CONTRACT,
//   PAYMENT,
//   DEALER_DOCUMENTS,
//   AUCTION_STATUS
//   // todo: in der legacy setzen wir noch prospectingStatus. Das können wir aktuell nicht anzeigen
// }

// export interface AuctionReminder {
//   senderId: string;
//   senderDisplayName: string;
//   type: ReminderTypes;
//   sentAt: Date;
// }

const auctionReminderTextResources = buildTextResources4Data<AuctionReminder>({
  scope: "Data",
  namespace: dataNamespace(Domains.auction, "AuctionReminder"),
  resources: {
    sender: "Sender",
    senderId: "SenderId",
    senderDisplayName: "Sender",
    type: "ReminderType",
    sentAt: "Sent at"
  }
});

const AuctionReminderDataKit = dataKitBuilder(auctionReminderTextResources).build();

const legacyAuctionRenegotiationTextResources = buildTextResources4Data<LegacyAuctionRenegotiation>({
  scope: "Data",
  namespace: dataNamespace(Domains.auction, "AuctionAnnotations"),
  resources: {
    price: "Price",
    _offeredBy: "Created By",
    createdAt: "Created At"
  }
});
const LegacyAuctionRenegotiationDataKit = dataKitBuilder(legacyAuctionRenegotiationTextResources).build();

const auctionAnnotationsTextResources = buildTextResources4Data<IAuctionAnnotation>({
  scope: "Data",
  namespace: dataNamespace(Domains.auction, "AuctionAnnotations"),
  resources: {
    creator: "Created By",
    createdAt: "Created At",
    text: "Message"
  }
});

const AuctionAnnotationDataKit = dataKitBuilder(auctionAnnotationsTextResources).build();

const auctionFeesTextResources = buildTextResources4Data<IAuctionFees>({
  scope: "Data",
  namespace: dataNamespace(Domains.auction, "AuctionFees"),
  resources: {
    fee: "Fee (Net)",
    feeGross: "Fee (Gross)",
    tax: "Tax",
    extraFee: "Extra Fee",
    dealerFee: "Dealer Fee",
    total: "Total",
    motorhammerCollectsFee: "MotorHammer collects fee"
  }
});
const AuctionFeesDataKit = dataKitBuilder(auctionFeesTextResources).build();

const auctionMailsSentTextResources = buildTextResources4Data<AuctionMailsSent>({
  scope: "Data",
  namespace: dataNamespace(Domains.auction, "AuctionMailsSent"),
  resources: {
    sentBy: "Sender",
    sentAt: "Sent at"
  }
});
const AuctionMailsSentDataKit = dataKitBuilder(auctionMailsSentTextResources).build();

export type LastBidUI = Omit<LastBid, "bidderId" | "bidderDisplayName">;
const lastBidTextResources = buildTextResources4Data<LastBidUI>({
  scope: "Data",
  namespace: dataNamespace(Domains.auction, "LastBid"),
  resources: {
    id: "ID",
    amount: "Amount",
    count: "Count",
    createdAt: "Created At"
  }
});
const LastBidDataKit = dataKitBuilder(lastBidTextResources).build();

export type BidderInfoUI = BidderInfo & { bidder?: IUserMin };
const bidderInfoTextResources = buildTextResources4Data<BidderInfoUI>({
  scope: "Data",
  namespace: dataNamespace(Domains.auction, "BidderInfo"),
  resources: {
    bidder: "Highest Bidder",
    bidderId: "Highest Bidder",
    bidderDisplayName: "Highest Bidder"
  }
});

const BidderInfoDataKit = dataKitBuilder(bidderInfoTextResources)
  .field("bidder")
  .props({ complex: UserMinDataKit })
  .build();

const auctionStatisticsTextResources = buildTextResources4Data<AuctionStatistics>({
  scope: "Data",
  namespace: dataNamespace(Domains.auction, "AuctionMailsSent"),
  resources: {
    visitorCount: "Visitor Count",
    starredCount: "Starred Count"
  }
});

const AuctionStatisticsDataKit = dataKitBuilder(auctionStatisticsTextResources).build();

// export interface AuctionDetails extends AuctionListItem {
//   _dealer?: IDealer;
//   _visitors?: UserMin[];
//   _starredBy?: UserMin[];
//   _car: ICarV2;
//   _carBundle: [ICarV2];
//   auctionReminders?: AuctionReminder[];
//   startedBy?: UserMin;
//   reservePrice?: number;
//   isProspectDeal?: boolean;
// }

const auctionDetailsTextResources = buildTextResources4Data<IAuctionDetailsUI>({
  scope: "Data",
  namespace: "Auction",
  resources: {
    id: "ID",
    auctionType: "Type",
    auctionEndTime: "Endtime",
    startPrice: "Start Price",
    stopPrice: "Stop Price",
    reservePrice: "Reserve Price",
    targetPrice: "Target Price",
    isProspectDeal: "Deal/noDeal",
    ownerOrgName: "Organization",
    car: "Car",
    lastBid: "Last bid",
    bidderInfo: "Bidder Info",
    // hasAuctionEnded: "auctionEnded",
    // _highestBidder: "Highest Bidder",
    // images: "Images",
    mainImage: "Main Image",
    ownerOrgId: "Organization Id",
    // _car: "Car",
    // _carBundle: "Car Bundle",
    carBundle: "Car Bundle",

    // _dealer: "Dealer",
    // visitors: "Visitors",
    // starredBy: "Starred",
    statistics: "Statistics",
    auctionReminders: "Reminders sent",
    startedBy: "Started by",
    createdAt: "Auction Start",
    auctionMailsSent: "Auction Mails sent",
    auctionFees: "Fees",
    annotations: "Annotations",
    prospectDate: "Prospect Date",
    dueDate: "Due Date",
    state: "Auction State",
    // steps: "Steps"
    stepAmount: "Step amount",
    auctionDuration: "Auction duration",
    renegotiation: "Renegotiation",
    alternativePriceAccepted: "Alternative price accepted"
  }
});
// const auctionDetailsTextResources = AuctionListItemDataKit.extendTextResources<IAuctionDetailsUI>({
//   // const auctionDetailsTextResources = buildTextResources4Data<IAuctionDetailsUI>({

//   ownerOrgId: "Organization Id",
//   _car: "Car",
//   _carBundle: "Car Bundle",
//   car: "Car",
//   carBundle: "Car Bundle",

//   // _dealer: "Dealer",
//   visitors: "Visitors",
//   starredBy: "Starred",
//   lastBid: "Last Bid",
//   statistics: "Statistics",
//   auctionReminders: "Reminders sent",
//   startedBy: "Started by",
//   // auctionStartTime: "Auction Start",
//   auctionMailsSent: "Auction Mails sent",
//   auctionFees: "Fees",
//   annotations: "Annotations",
//   prospectDate: "Prospect Date",
//   dueDate: "Due Date",
//   state: "Auction State"
//   // auctionEndTime: "Auction End"

//   // reservePrice: "Reserve Price",
//   // isProspectDeal: "Deal / no Deal"
// });

export const AuctionDetailsDataKit = dataKitBuilder(auctionDetailsTextResources)
  .field("auctionFees")
  .props({ complex: AuctionFeesDataKit })
  // .field("auctionType")
  // .props({
  //   options: buildSelectOptions(AuctionType, auctionTypeTextResources)
  // })
  .field("car")
  .props({ complex: CarDetailsDataKit })
  .field("carBundle")
  .props({ complex: CarDetailsDataKit })
  // .field("visitors")
  // .props({ complex: UserMinDataKit })
  .field("startedBy")
  .props({ complex: UserMinDataKit })
  // .field("starredBy")
  // .props({ complex: UserMinDataKit })
  // .field("_highestBidder")
  // .props({ complex: UserMinDataKit })
  // .field("steps")
  // .props({ complex: DutchStepDataKit })
  .field("lastBid")
  .props({ complex: LastBidDataKit })
  .field("statistics")
  .props({ complex: AuctionStatisticsDataKit })
  .field("bidderInfo")
  .props({ complex: BidderInfoDataKit })
  .field("auctionReminders")
  .props({ complex: AuctionReminderDataKit })
  .field("renegotiation")
  .props({ complex: LegacyAuctionRenegotiationDataKit })
  .field("annotations")
  .props({ complex: AuctionAnnotationDataKit })
  .field("auctionMailsSent")
  .props({ complex: AuctionMailsSentDataKit })
  // .field("_dealer")
  // .props({ complex: DealerDataKit })
  .queries(qb => ({
    getAuctionDetails: qb
      .query("getAuctionDetails")
      .args<AuctionDetailsArgs>("GetAuctionDetailsArgs", true)
      .Result.allFields(true)
      .subBuilder("annotations", builder => {
        builder.allFields(true);
      })
      .subBuilder("startedBy", builder => {
        builder.selectFields("id", "email", "phone", "firstname", "lastname", "username");
      })
      .subBuilder("auctionFees", builder => {
        builder.allFields(true);
      })
      .subFields("auctionReminders", "type", "sentAt", "senderDisplayName")
      .subFields("lastBid", "id", "amount", "count", "createdAt")
      .subFields("statistics", "visitorCount", "starredCount")
      .subFields("bidderInfo", "bidderId", "bidderDisplayName")
      .subFields("auctionMailsSent", "sentAt")
      // .subFields("steps", "amount", "stepStartTime", "stepEndTime")
      .subFields("mainImage", "uri", "mediaType", "v2")
      .subBuilder("car", builder =>
        builder
          .allFields(true)
          .subFields("assessment", "statusChecks", "tireSets", "paintCoating")
          .subFields("createdBy", "id", "vDisplayName")
      )
      .subBuilder("carBundle", builder => {
        // builder.selectFields(
        //   "id",
        //   "maker",
        //   "model",
        //   "vin",
        //   "initialRegistration",
        //   "mileage",
        //   "isCloneable",
        //   "purchasePrice"
        // );
        builder
          .allFields(true)
          .subFields("assessment", "statusChecks", "tireSets", "paintCoating")
          .subFields("createdBy", "id", "vDisplayName");
      })
      .build(),
    // getAuctionsInWindow: qb
    //   .query("getAuctionsInWindow")
    //   .args<AuctionsInWindowArgs>("AuctionsInWindowArgs", true)
    //   .ListResult.selectFields("id", "auctionEndTime", "maker", "model")
    //   .build(),

    getBidHistory: qb
      .query("getBidHistory", { includeCV: true })
      .args<GetBidHistoryArgs>("GetBidHistoryArgs", true)
      .ListResult.customType<IBid>()
      .selectFields("id", "bidderName", "amount", "deviceType", "isAlternativeBid", "createdAt")
      .build(),
    // getBidsBelowStartprice: qb
    //   .query("getBidHistory", { includeCV: true })
    //   .args<GetBidHistoryArgs>("GetBidHistoryArgs", true)
    //   .ListResult.customType<IBid>()
    //   .selectFields("id", "bidderName", "amount", "deviceType", "isAlternativeBid", "createdAt")
    //   .build(),
    sendAuctionReminder: qb
      .mutation("sendAuctionReminder")
      .args<SendAuctionReminderArgs>("SendAuctionReminderInput", true)
      .Result.selectFields("id")
      .subFields("auctionReminders", "type", "sentAt", "senderDisplayName")
      .build(),
    legacySendRenegotiationMail: qb
      .mutation("legacySendRenegotiationMail")
      .args<LegacyAuctionRenegotiationArgs>("LegacyAuctionRenegotiationInput", true)
      .Result.selectFields("id")
      .subFields("renegotiation", "_offeredBy", "createdAt", "price")
      .build(),
    setDealStatus: qb
      .mutation("setDealStatus")
      .args<SetDealStatusArgs>("SetDealStatusArgs", true)
      .Result.selectFields("isProspectDeal")
      .build(),
    acceptAlternativeBid: qb
      .mutation("acceptAlternativeBid")
      .args<AcceptAlternativeBidArgs>("AcceptAlternativeBidArgs", true)
      .Result.selectFields("id", "lastBid", "bidderInfo", "auctionFees", "alternativePriceAccepted")
      .subFields("lastBid", "amount", "count", "createdAt", "id")
      .subFields("bidderInfo", "bidderId", "bidderDisplayName")
      .subBuilder("auctionFees", builder => {
        builder.allFields(true);
      })
      .build(),
    editAnnotations: qb
      .mutation("editAnnotations")
      .args<EditAnnotationsArgs>("EditAnnotationsArgs", true)
      .Result.selectFields("id")
      .subFields("annotations", "createdAt", "creator", "text")
      .build(),
    legacyCloneCar: qb
      .mutation("legacyCloneCar")
      .args<LegacyCloneCarArgs>("LegacyCloneCarArgs", true)
      .Result.customType<Partial<ICarV2>>()
      .selectFields("id")
      .build(),
    legacyRetriggerAuctionMail: qb
      .mutation("legacyRetriggerAuctionMail")
      .args<LegacyRetriggerAuctionMailArgs>("LegacyRetriggerAuctionMailArgs", true)
      .PrimitiveResult<boolean>()
      .build(),
    legacyStartAuction: qb
      .mutation("legacyStartAuction")
      .args<LegacyStartAuctionArgs>("LegacyStartAuctionArgs", true)
      .Result.selectFields("auctionEndTime", "id")
      .build(),
    deleteAuction: qb
      .mutation("deleteAuction")
      .args<DeleteAuctionArgs>("DeleteAuctionArgs", true)
      .PrimitiveResult<boolean>()
      .build(),

    pendingAuctionChanged: qb
      .subscription(PubSubEventTypeMH.pendingAuctionChanged, { typeName: "Auction" })
      .args<CommonSubscribeByOrgIdArgs>("CommonSubcribeByOrgIdInput", false)
      // .Result.allFields(true)
      .Result.selectFields("id", "lastBid", "statistics", "auctionFees")
      .subFields("lastBid", "amount", "count", "createdAt", "id")
      .subFields("statistics", "starredCount", "visitorCount")
      .subBuilder("auctionFees", builder => {
        builder.allFields(true);
      })
      // .subBuilder("_highestBidder", builder => {
      //   builder.selectFields("vDisplayName");
      // })
      .build()
  }))
  .build();
