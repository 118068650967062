import {
  RecoverPasswordRoute,
  SecondFactorVerificationRoute,
  SignUpRoute,
  VerifyEmailRoute
} from "../pages/account/routes";
import { SignInRoute } from "../pages/account/SignInPage";
import { SignOutRoute } from "../pages/account/SignOutPage";
import { AccountDetailsRoute, AccountListRoute } from "../views/accountDetails/route";
import { AccountSessionDetailsRoute, AccountSessionsRoute } from "../views/accountSession/route";
import { AuctionDetailsRoute, AuctionListRoute, EndedAuctionListRoute } from "../views/auction/route";
import { CarDetailsRoute, CarListRoute } from "../views/car/route";
import { OrganizationInfoRoute, PersonalInfoRoute } from "../exViews/personalInfo/route";
import { TaskDetailsRoute, TaskListRoute } from "../views/task/route";
import { TextResourcesRoute } from "../views/textResources/route";
import {
  AdminConfigsRoute,
  AdminEnvironmentModelRoute,
  AdminEnvironmentsRoute,
  DashboardRoute
  // DealerStatisticsRoute
} from "./routes";
import { DealerDetailsRoute, DealerListRoute } from "../views/dealerDetails/route";
import { OrganizationListRoute, OrganizationDetailsRoute } from "../views/organization/route";
import { SecurityRoleRoute } from "../views/securityRole/route";
import { ExCarDetailsRoute, ExCarListRoute } from "../exViews/car/route";
import { ExAuctionDetailsRoute, ExAuctionListRoute } from "../exViews/auction/route";

export const AppRoutes = [
  SignInRoute,
  SignUpRoute,
  SignOutRoute,
  DashboardRoute,
  VerifyEmailRoute,
  SecondFactorVerificationRoute,
  RecoverPasswordRoute,
  PersonalInfoRoute,
  OrganizationInfoRoute,
  AdminEnvironmentModelRoute,
  AdminEnvironmentsRoute,
  AdminConfigsRoute,
  AccountSessionsRoute,
  AccountListRoute,
  AccountDetailsRoute,
  TaskListRoute,
  TaskDetailsRoute,
  AccountSessionDetailsRoute,
  TextResourcesRoute,
  OrganizationListRoute,
  OrganizationDetailsRoute,
  SecurityRoleRoute,

  // ######### unapproved
  AuctionDetailsRoute,
  AuctionListRoute,
  EndedAuctionListRoute,
  CarListRoute,
  DealerListRoute,
  DealerDetailsRoute,
  CarDetailsRoute,
  ExCarDetailsRoute,
  ExCarListRoute,
  ExAuctionDetailsRoute,
  ExAuctionListRoute
];
