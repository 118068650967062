import { buildTextResources4Enum, enumNamespace, ResourceKey } from "@emibee/lib-app-common";
import { AuctionState, AuctionType, SecurityAttributeScope, UserType } from "@mh/common";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ScheduleIcon from "@mui/icons-material/Schedule";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { Domains } from "../core/textResourceScopes";
import { IAuctionListItemUI } from "../data/auction";
import { IAuctionDetailsUI } from "../data/auction/AuctionDetails";
import { WidgetFilterDefinition } from "../exControls/widget/useWidgetFilter";

export function getSecurityAttributeScope(userType?: UserType) {
  if (userType === UserType.Dealer || userType === UserType.Admin || userType === UserType.System_Admin) {
    return SecurityAttributeScope.max;
  } else {
    return SecurityAttributeScope.min;
  }
}

export const isProspectingOrDutch = (auction: IAuctionDetailsUI | IAuctionListItemUI) => {
  return auction && (auction.auctionType === AuctionType.dutch || auction.auctionType === AuctionType.prospect);
};

export const hasAuctionEnded = (auction: IAuctionDetailsUI) => {
  return auction && (auction.auctionEndTime < Date.now() || auction.state === AuctionState.ended);
};

export const isAuctionSold = (auction: IAuctionDetailsUI) => {
  const startPrice = auction.startPrice || 0;
  return (
    auction &&
    hasAuctionEnded(auction) &&
    auction.lastBid &&
    (auction.lastBid.amount > startPrice ||
      auction.alternativePriceAccepted ||
      (isProspectingOrDutch(auction) && auction.isProspectDeal))
  );
};

export const AuctionFilterTextResources = buildTextResources4Enum<typeof AuctionFilterTypes>({
  scope: "Data",
  namespace: enumNamespace(Domains.auction, "AuctionType"),
  resources: {
    all: "All",
    pending: "Pending",
    ended: "Ended",
    prospectOpen: "Open",
    prospectDeal: "Deal",
    prospectNoDeal: "NoDeal"
  }
});

export enum AuctionFilterTypes {
  all,
  pending,
  ended,
  prospectOpen,
  prospectDeal,
  prospectNoDeal
}

export const buildAuctionFilters = (enumValues: AuctionFilterTypes[]): WidgetFilterDefinition<AuctionFilterTypes>[] => {
  return enumValues.map(type => {
    let title: ResourceKey;
    let icon: React.ReactNode;

    switch (type) {
      case AuctionFilterTypes.all:
        title = AuctionFilterTextResources.all;
        icon = <FilterListOffIcon />;
        break;
      case AuctionFilterTypes.pending:
        title = AuctionFilterTextResources.pending;
        icon = <ScheduleIcon />;
        break;
      case AuctionFilterTypes.ended:
        title = AuctionFilterTextResources.ended;
        icon = <HourglassTopIcon />;
        break;
      case AuctionFilterTypes.prospectOpen:
        title = AuctionFilterTextResources.prospectOpen;
        icon = <RadioButtonUnchecked />;
        break;
      case AuctionFilterTypes.prospectDeal:
        title = AuctionFilterTextResources.prospectDeal;
        icon = <CheckCircleOutlineIcon />;
        break;
      case AuctionFilterTypes.prospectNoDeal:
        title = AuctionFilterTextResources.prospectNoDeal;
        icon = <CancelIcon />;
        break;
      default:
        throw new Error(`Unknown filter type: ${type}`);
    }
    return {
      type,
      title,
      icon
    };
  });
};

export function filterAuctions(auction: IAuctionListItemUI, type: AuctionFilterTypes[]) {
  return (
    !type ||
    (type.includes(AuctionFilterTypes.pending) && auction.auctionEndTime > Date.now()) ||
    (type.includes(AuctionFilterTypes.ended) && auction.auctionEndTime < Date.now()) ||
    (type.includes(AuctionFilterTypes.prospectOpen) &&
      isProspectingOrDutch(auction) &&
      auction.isProspectDeal === null) ||
    (type.includes(AuctionFilterTypes.prospectDeal) &&
      isProspectingOrDutch(auction) &&
      auction.isProspectDeal === true) ||
    (type.includes(AuctionFilterTypes.prospectNoDeal) &&
      isProspectingOrDutch(auction) &&
      auction.isProspectDeal === false)
  );
}
