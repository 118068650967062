import { GraphQueryType, PrefetchData, resolveOptionLabel, useLocalize } from "@emibee/lib-app-common";
import DateRangeChipIcon from "@mui/icons-material/EventAvailable";
import MultiSelectChipIcon from "@mui/icons-material/ListAlt";
import { DateRange } from "@mui/lab/DateRangePicker";
import { LocalizationProvider, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";

import {
  DateRangeFetchMore,
  FetchMoreArgs,
  MaxDate,
  MinDate,
  isDateRangeFetchMoreArgs,
  isFetchMoreArgs,
  isMultiSelectFetchMoreArgs
} from "@emibee/lib-shared";
import { SvgIconProps } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import { addDays, subDays } from "date-fns";
import * as React from "react";
import { useStyles } from "../../Theme";
import { useFormatters } from "../../tools/formatters";
import { endOfDay, endOfMonth, gmtToLocal, startOfDay, startOfMonth } from "../../tools/utils";
import { DataRangePickerButton } from "../DateRangePickerButton";
import {
  DataGridColumnDef,
  DataGridDefinition,
  DataGridFilterModel,
  DataGridLogicOperator,
  DateRangeFilterPreset
} from "../dataGrid/DataGrid";

interface ToolbarSearchProps {
  range?: DateRange<Date> | null;
  anchorEl?: HTMLElement | null;
  onClose: () => void;
  onChange: (value: [Date, Date]) => void;
  //   placeholder?: string;
}

export function ToolbarDataRangePicker(props: ToolbarSearchProps) {
  const { anchorEl, onClose, onChange, range } = props;
  const rangeRef = React.useRef<DateRange<Date>>(rangeToLocalTime(range) ?? [null, null]);

  const { css } = useStyles();
  const handleChange = () => {
    const from = startOfDay(rangeRef.current[0]!, true); // gmt;
    const to = endOfDay(rangeRef.current[1]!, true); //gmt
    onChange([from, to]);
  };
  React.useEffect(() => {
    return () => {
      if (rangeRef.current !== range && rangeRef.current[0] && rangeRef.current[1]) {
        handleChange();
        // console.log("RangePicker.onChange", rangeRef.current);
      }
    };
    // by intention range should be initial value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ClickAwayListener
      onClickAway={ev => {
        // console.log("onClickAway", ev, anchorEl);
        if (!anchorEl!.contains(ev.target as HTMLElement)) {
          onClose();
        }
      }}
    >
      <Popper open={!!anchorEl} anchorEl={anchorEl} disablePortal className={css({ zIndex: 2 })}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Paper>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              calendars={3}
              // maxDate={new Date()}
              value={rangeRef.current}
              onChange={(newValue: any) => {
                rangeRef.current = newValue;
                if (!range && rangeRef.current[0] && rangeRef.current[1]) {
                  handleChange();
                  rangeRef.current = [] as never; // we dont want another change on close
                  onClose();
                }

                // console.log("RangePicker", newValue);
              }}
              slotProps={
                {
                  textField: { variant: "outlined" }
                } as any
              }
              // slotProps={{ textField: { variant: "outlined" } }}
              // renderInput={(props: any) => <TextField {...props} helperText="invalid mask" />}
              // renderInput={(startProps: any, endProps: any) => {
              //   return (
              //     <React.Fragment>
              //       <TextField {...startProps} />
              //       {/* <Box sx={{ mx: 2 }}> to </Box> */}
              //       <TextField {...endProps} />
              //     </React.Fragment>
              //   );
              // }}
            />
          </Paper>
        </LocalizationProvider>
      </Popper>
    </ClickAwayListener>
  );
}

function rangeToLocalTime(range?: DateRange<Date> | null) {
  if (range) {
    return range.map(r => r && gmtToLocal(r)) as DateRange<Date> | null;
  } else return range;
}

interface ToolbarDateRangeChipProps {
  range: [Date, Date];
  field: string;
  onDelete: () => void;
}

function ToolbarDateRangeChip(props: ToolbarDateRangeChipProps) {
  const { range, ...rest } = props;
  const formatters = useFormatters();
  return (
    <DatagridFilterChip
      {...rest}
      IconComponent={DateRangeChipIcon}
      content={`${formatters.date()(gmtToLocal(range[0]))} - ${formatters.date()(gmtToLocal(range[1]))}`}
    />
  );
}

interface DatagridFilterChipProps {
  content: React.ReactNode;
  field: string;
  IconComponent: React.FunctionComponent<SvgIconProps>;
  onDelete: () => void;
}

function DatagridFilterChip(props: DatagridFilterChipProps) {
  const { content, field, IconComponent, onDelete } = props;
  return (
    <Chip
      sx={{ marginLeft: 1, marginRight: 1 }}
      label={
        <Box display="flex" alignItems="center">
          <IconComponent color="action" sx={{ marginRight: 1 }} />
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" sx={{ lineHeight: 1.2, fontWeight: "bold" }}>
              {field}
            </Typography>
            <Typography variant="caption" sx={{ lineHeight: 1.2 }}>
              {content}
            </Typography>
          </Box>
        </Box>
      }
      onDelete={onDelete}
    />
  );
}

interface MasterDetailTableProps {
  masterGraphQuery?: GraphQueryType<any>;
  masterLoadArgs?: object | null;
  prefetch?: PrefetchData<any>;
  gridProps: DataGridDefinition;
  fetchMoreFilterDisabled?: boolean;
  toolbarCustomComponents?: React.ReactNode;
}

interface FetchMoreFilter {
  fetchArgs?: FetchMoreArgs<any, any>;
  field?: string;
  dateRange?: [Date, Date] | null;
  maxRange?: [Date, Date];
  options?: (number | string)[] | null;
  allOptions?: (number | string)[];
}

function useFetchMoreArgs(props: MasterDetailTableProps) {
  const { prefetch, masterLoadArgs, fetchMoreFilterDisabled } = props;

  const [fetchMoreFilter, setFetchMoreFilter] = React.useState<undefined | FetchMoreFilter>(() => {
    const fetchArgs = prefetch?.args ?? masterLoadArgs ?? undefined;
    if (!fetchMoreFilterDisabled && fetchArgs && isFetchMoreArgs(fetchArgs)) {
      if (isDateRangeFetchMoreArgs(fetchArgs) && fetchArgs.fetchMoreArgs?.ranges.length === 1) {
        const range = fetchArgs.fetchMoreArgs.ranges[0];
        return { fetchArgs, field: fetchArgs.fetchMoreArgs.field, dateRange: [range.from, range.to] };
      } else if (isMultiSelectFetchMoreArgs(fetchArgs) && fetchArgs.fetchMoreArgs) {
        return { fetchArgs, field: fetchArgs.fetchMoreArgs.field, options: fetchArgs.fetchMoreArgs.options };
      }
    }

    return undefined;
  });

  let changeDateRange: undefined | ((range?: [Date, Date]) => void) = undefined;
  let changeOptions: undefined | ((options?: (number | string)[]) => void) = undefined;
  if (fetchMoreFilter) {
    const update = (filter: FetchMoreFilter) => setFetchMoreFilter(current => ({ ...current, ...filter }));
    if (fetchMoreFilter.dateRange !== undefined) {
      changeDateRange = handleDateRangeFetchArgs(fetchMoreFilter, update);
    } else if (fetchMoreFilter.options !== undefined) {
      changeOptions = handleMultiSelectFetchArgs(fetchMoreFilter, update);
    }
  }

  return {
    fetchMoreFilter,
    changeDateRange,
    changeOptions
  };
}

function handleDateRangeFetchArgs(filter: FetchMoreFilter, update: (filter: FetchMoreFilter) => void) {
  const { maxRange, fetchArgs, field } = filter;

  return (r?: [Date, Date]) => {
    update({ dateRange: r ?? null });

    // const isOverlapping = () => maxRange && r && r[0] <= maxRange[1] && r[1] >= maxRange[0];

    // calc new min/max
    // new Range must be overlapping with existing minMaxRange
    // let newMin: Date | undefined = undefined;
    // if (r && (!maxRange || (r[0] < maxRange[0] && r[1] >= maxRange[0]))) {
    //   newMin = r[0];
    // }
    // let newMax: Date | undefined = undefined;

    // if (r && (!maxRange || (r[1] > maxRange[1] && r[0] <= maxRange[1]))) {
    //   newMax = r[1];
    // }

    // // set new min/max
    // if (newMin || newMax) {
    //   update({ maxRange: [newMin ?? (maxRange && maxRange[0])!, newMax ?? (maxRange && maxRange[1])!] });
    // }

    // create ranges
    const ranges: DateRangeFetchMore["ranges"] = r ? [{ from: r[0], to: r[1] }] : [{ from: MinDate, to: MaxDate }];
    // if (r) {
    //   if (maxRange && isOverlapping()) {
    //     if (newMin) {
    //       ranges.push({ from: newMin, to: maxRange[0] });
    //     }
    //     if (newMax) {
    //       ranges.push({ from: maxRange[1], to: newMax });
    //     }
    //   } else {
    //     ranges.push({ from: r[0], to: r[1] });
    //   }
    // }

    update({
      fetchArgs:
        ranges.length && field
          ? {
              args: fetchArgs?.args,
              fetchMoreArgs:
                ranges.length === 0
                  ? null
                  : {
                      field,
                      ranges
                    }
            }
          : { args: fetchArgs?.args }
    });
  };
}

function handleMultiSelectFetchArgs(filter: FetchMoreFilter, update: (filter: FetchMoreFilter) => void) {
  const { allOptions, options, fetchArgs, field } = filter;

  return (newOptions?: (number | string)[]) => {
    // store new selection
    update({ options: newOptions ?? null });

    const allOptionsNew = allOptions ?? options ?? [];
    let missingOptions: (number | string)[] = [];

    if (newOptions) {
      missingOptions = newOptions.filter(o => !allOptionsNew.includes(o));

      if (missingOptions.length > 0) {
        update({ allOptions: [...allOptionsNew, ...missingOptions] });
      } else if (!allOptions) {
        update({ allOptions: allOptionsNew });
      }
    }

    update({
      fetchArgs:
        newOptions && field
          ? {
              args: fetchArgs?.args,
              fetchMoreArgs:
                missingOptions.length === 0
                  ? null
                  : {
                      field,
                      options: missingOptions
                    }
            }
          : { args: fetchArgs?.args }
    });
  };
}

export function useFetchMoreFilter(props: MasterDetailTableProps) {
  const { gridProps } = props;

  const { fetchMoreFilter, changeDateRange, changeOptions } = useFetchMoreArgs(props);
  const localize = useLocalize();
  if (fetchMoreFilter) {
    let toolbarCustomComponents: React.ReactNode | undefined = undefined;
    let datagridToolbarCustomComponent: React.ReactNode | undefined = undefined;
    const getColumn = () => {
      const col = gridProps.columns.find(col => col.field === fetchMoreFilter.field);
      if (!col) throw new Error("Column not found: " + fetchMoreFilter.field);
      else return col as DataGridColumnDef;
    };

    if (changeDateRange) {
      const range = fetchMoreFilter.dateRange;

      // must be reset cause of closure variables
      // if (!gridProps.onFilterModelChange) {
      gridProps.onFilterModelChange = model => {
        gridProps.filterModel = updateDateRangeFilterModel(model, getColumn(), range, changeDateRange);
      };
      // }

      toolbarCustomComponents = <DataRangePickerButton range={range} changeDateRange={changeDateRange} />;

      datagridToolbarCustomComponent = range ? (
        <ToolbarDateRangeChip
          key="dateRange"
          onDelete={() => changeDateRange(undefined)}
          range={range}
          field={getColumn().headerName!}
        />
      ) : (
        undefined
      );
      gridProps.filterModel = updateDateRangeFilterModel(gridProps.filterModel, getColumn(), range);
    } else if (changeOptions) {
      const options = fetchMoreFilter.options;

      // must be reset cause of closure variables
      // if (!gridProps.onFilterModelChange) {
      gridProps.onFilterModelChange = model => {
        gridProps.filterModel = updateMultiSelectFilterModel(model, getColumn(), options, changeOptions);
      };
      // }

      datagridToolbarCustomComponent = options ? (
        <DatagridFilterChip
          key="multiselect"
          IconComponent={MultiSelectChipIcon}
          onDelete={() => changeOptions(undefined)}
          content={options.map(opt => resolveOptionLabel(getColumn().options!, opt, localize)).join(", ")}
          field={getColumn().headerName!}
        />
      ) : (
        undefined
      );
      gridProps.filterModel = updateMultiSelectFilterModel(gridProps.filterModel, getColumn(), options);
    }

    return {
      toolbarCustomComponents,
      datagridToolbarCustomComponent,
      datagridProps: gridProps,
      loadArgs: fetchMoreFilter.fetchArgs
    };
  } else {
    return {
      datagridProps: gridProps,
      loadArgs: props.masterLoadArgs,
      toolbarCustomComponents: props.toolbarCustomComponents
    };
  }
}

export function buildDateRangeFetchMoreArgsFromPreset(preset: DateRangeFilterPreset): DateRangeFetchMore["ranges"] {
  const dr = buildDateRangePreset(preset);
  if (!dr) throw new Error("buildDateRangePreset did not return a range");
  return [{ from: dr[0], to: dr[1] }];
}

function getToday(endOfDay?: boolean) {
  if (endOfDay) {
    return new Date().setUTCHours(23, 59, 59, 999);
  } else {
    return new Date().setUTCHours(0, 0, 0, 0);
  }
}

function buildDateRangePreset(preset?: DateRangeFilterPreset): [Date, Date] | undefined {
  if (preset?.thisMonth) {
    const from = startOfMonth(new Date(), true);
    // const to = endOfDay(new Date(), true);
    const to = endOfMonth(new Date(), true);

    return [from, to];
  } else if (preset?.latestDaysTilNow) {
    const from = subDays(getToday(), preset.latestDaysTilNow);
    let to = new Date();
    if (preset.daysFromNow) {
      to = addDays(getToday(), preset.daysFromNow);
    }
    return [from, to];
  } else if (preset?.daysFromNow) {
    const from = new Date();
    const to = addDays(getToday(), preset.daysFromNow);
    return [from, to];
  } else if (preset?.pastDaysAndFuture !== undefined) {
    const from = subDays(getToday(), preset.pastDaysAndFuture);

    return [from, MaxDate];
  } else if (preset?.middleOfDaysRange) {
    const from = subDays(getToday(), preset.middleOfDaysRange / 2);
    const to = addDays(getToday(true), preset.middleOfDaysRange / 2);

    return [from, to];
  } else if (preset?.range && preset.range.length === 2) {
    const from = preset.range[0];
    const to = preset.range[1];
    return [from, to];
  } else return undefined;
}

function updateDateRangeFilterModel(
  model: DataGridFilterModel | undefined,
  column: DataGridColumnDef,
  range: [Date, Date] | undefined | null,
  setRange?: (range?: [Date, Date]) => void
): DataGridFilterModel {
  model = model ?? { items: [] };
  model.items = [...model.items];
  model.logicOperator = DataGridLogicOperator.And;

  const sync = !!setRange;
  type FilterType = "from" | "to";
  const id = (type: FilterType) => `dr_${type}`;
  const getFilter = (type: FilterType) => model && model.items.find(item => item.id === id(type));
  const formatValue = (date: Date) => date.toISOString().substring(0, column.type === "dateTime" ? 19 : 10);
  const clearFilter = () => {
    if (model) {
      model.items = model.items.filter(item => item.id !== id("from") && item.id !== id("to"));
    }
  };
  if (!range) {
    clearFilter();
  } else if (sync) {
    const fromItem = getFilter("from");
    const toItem = getFilter("to");

    let clear = false;
    let rangeChanged = false;
    if (!fromItem || fromItem.operator !== "onOrAfter") {
      clear = true;
    } else if (fromItem.value !== formatValue(range[0])) {
      range[0] = new Date(fromItem.value);
      rangeChanged = true;
    }
    if (!toItem || toItem.operator !== "before") {
      clear = true;
    } else if (toItem.value !== formatValue(range[1])) {
      range[1] = new Date(toItem.value);
      rangeChanged = true;
    }
    if (clear) {
      if (fromItem) {
        fromItem.id += "_c";
      }
      if (toItem) {
        toItem.id += "_c";
      }
      clearFilter();
      setRange();
    } else if (rangeChanged) {
      setRange([...range]);
    }
  } else {
    const upsert = (type: FilterType) => {
      const date = range[type === "from" ? 0 : 1];
      let item = getFilter(type);
      if (!item) {
        item = {
          field: column.field,
          operator: type === "from" ? "onOrAfter" : "before",
          id: id(type)
        };
        model!.items.push(item);
      }
      item.value = formatValue(date);
    };
    upsert("from");
    upsert("to");
  }

  return { ...model };
}

function updateMultiSelectFilterModel(
  model: DataGridFilterModel | undefined,
  column: DataGridColumnDef,
  options: (string | number)[] | undefined | null,
  changeOptions?: (options?: (number | string)[]) => void
): DataGridFilterModel {
  model = model ?? { items: [] };
  model.items = [...model.items];

  const id = "ms_";
  const getFilter = () => model && model.items.find(item => item.id === id);
  const clearFilter = () => {
    if (model) {
      model.items = model.items.filter(item => item.id !== id);
    }
  };
  let item = getFilter();

  const sync = !!changeOptions;
  if (!options) {
    clearFilter();
  } else if (sync) {
    if (item) {
      changeOptions(item.value);
    } else {
      changeOptions();
    }
  } else {
    if (!item) {
      item = {
        field: column.field,
        operator: "includes",
        id
      };
      model!.items.push(item);
    }
    item.value = options;
  }

  return { ...model };
}
